import styled, { css, keyframes } from 'styled-components';
import Img from 'gatsby-image';
import {
    absoluteCover,
    central,
    align,
    rectangle,
    backgroundCover,
    gap,
} from '@gannochenko/etc';
import { withEffects } from '@gannochenko/ui';
import { theme } from '../../../../style';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const arrow = require('./assets/arrow.svg') as string;

const bouncedAnimation = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(20px);
  }
`;

export const HeaderMainContainer = styled.header`
    position: relative;
    min-width: 320px;
    flex-shrink: 0;
`;

export const MenuOffset = styled.div`
    height: 40px;
`;


