import React, { FunctionComponent, ReactNode } from 'react';
import { MDXProvider } from '@mdx-js/react';

import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';

import { ThemeProvider } from 'styled-components';
// import { Provider as StateProvider } from "unstated"
import { theme, GlobalStyle, markdownComponents, MUITheme } from '../../style';

export const Root: FunctionComponent<{ element: ReactNode }> = ({
    element,
}) => {
    return (
        <MDXProvider components={markdownComponents}>

            <MUIThemeProvider theme={MUITheme}>

                <ThemeProvider theme={theme}>
                    <>
                        <GlobalStyle />
                        {element}
                    </>
                </ThemeProvider>

            </MUIThemeProvider>

        </MDXProvider>
    );
};
